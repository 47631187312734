<template>
    <div>
        <ReportTitleBar>轻页面报表</ReportTitleBar>
        <div class="main-container">
            <h1>{{ pageTitle }}</h1>
            <div class="info-items">
                <div class="info-item uv">
                    <h2>访问量</h2>
                    <h3>{{ uv }}人</h3>
                    <img src="../../assets/report/form-report-UV.png" />
                </div>
                <div class="info-item pv">
                    <h2>访问次数</h2>
                    <h3>{{ pv }}次</h3>
                    <img src="../../assets/report/form-report-PV.png" />
                </div>
                <div class="info-item submit">
                    <h2>提交量</h2>
                    <h3>{{ result.totalSize }}人</h3>
                    <img src="../../assets/report/form-report-submit.png" />
                </div>
            </div>
            <div class="table-toolbar">
                <h2>表单数据</h2>
                <Button class="export-btn" @click="exportData"
                    ><i
                        style="font-size: 13px"
                        class="nazaio-iconfont nz-export"
                    ></i>
                    导出</Button
                >
            </div>
            <div class="data-table">
                <Table border :columns="columns" :data="result.data"></Table>
                <div
                    class="pagination"
                    v-if="result.totalSize / query.pageSize > 1"
                >
                    <Page
                        @on-change="pageChange"
                        :total="result.totalSize"
                        :pageSize="query.pageSize"
                        show-elevator
                        show-total
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ReportTitleBar from "./components/ReportTitleBar";
import {
    getLightpageColumns,
    getSubmitRecordList,
    getReportDetail,
    exportExcel,
} from "@/api/lightpage";

export default {
    name: "Report",
    components: {
        ReportTitleBar,
    },
    data() {
        return {
            id: undefined,
            pageTitle: "",
            pv: undefined,
            uv: undefined,
            columns: [
                {
                    type: "index",
                    title: "序号",
                    resizable: true,
                    indexMethod: (row) => {
                        return (this.query.pageNum - 1) * this.query.pageSize + row._index + 1;
                    }
                },
            ],
            query: {
                pageId: undefined,
                pageNum: 1,
                pageSize: 10,
            },
            result: {
                totalSize: 0,
                data: [],
            },
        };
    },
    created() {
        this.id = this.$route.params.id;

        getReportDetail(this.id).then((response) => {
            this.pageTitle = response.pageTitle;
            this.pv = response.pv;
            this.uv = response.uv;
        });

        this.query.pageId = this.$route.params.id;
        getLightpageColumns(this.id).then((response) => {
            let columnBase = {
                resizable: true,
                render: (h, { row, column }) => {
                    return h("span", {}, row[column._index - 1]);
                },
            };
            response.forEach((r) => {
                let column = {};
                Object.assign(column, columnBase, { title: r });
                this.columns.push(column);
            });
            this.getList();
        });
    },
    mounted() {
        window.onresize = () => (this.screenWidth = window.innerWidth);
    },
    methods: {
        getList() {
            getSubmitRecordList(this.query).then((response) => {
                this.result.totalSize = response.totalSize;
                let rows = [];
                response.data.forEach((d) => {
                    let row = {};
                    d.forEach((c, index) => {
                        row[index] = c;
                    });
                    rows.push(row);
                });
                this.result.data = rows;
            });
        },
        pageChange(pageNum) {
            this.query.pageNum = pageNum;
            this.getList();
        },
        exportData() {
            exportExcel(this.id).then((response) => {
                let blob = new Blob([response.data], {
                    type: "application/vnd.ms-excel",
                });
                let fileName = "表单数据" + new Date().getTime() + ".xlsx";
                if (window.navigator.msSaveOrOpenBlob) {
                    navigator.msSaveBlob(blob, fileName);
                } else {
                    var link = document.createElement("a");
                    link.href = window.URL.createObjectURL(blob);
                    link.download = fileName;
                    link.click();
                    window.URL.revokeObjectURL(link.href);
                }
            });
        },
    },
};
</script>
<style lang="less" scoped>
.main-container {
    overflow-y: auto;

    &::-webkit-scrollbar {
        width: 5px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #d4dbe1;
    }
}

h1 {
    margin: 0;
    height: 32px;
    line-height: 32px;
    font-size: 24px;
    color: #0a2a4c;
}

.info-items {
    margin-top: 20px;
    display: flex;
    justify-content: flex-start;

    .info-item {
        position: relative;
        width: 325px;
        height: 148px;
        margin-right: 20px;
        box-shadow: 0px 2px 12px -4px rgba(0, 0, 0, 0.1);
        border-radius: 8px;
        background-color: #fff;
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;

        &.uv {
            background-image: url("../../assets/report/form-report-UV-bg.png");
        }

        &.pv {
            background-image: url("../../assets/report/form-report-PV-bg.png");
        }

        &.submit {
            background-image: url("../../assets/report/form-report-submit-bg.png");
        }
    }

    h2 {
        font-size: 16px;
        color: #8494a6;
        font-weight: normal;
        height: 24px;
        width: 80px;
        line-height: 24px;
        margin: 40px 0 0 32px;
    }

    h3 {
        font-size: 24px;
        color: #0a2a4c;
        height: 32px;
        width: 80px;
        line-height: 32px;
        margin: 12px 0 0 32px;
    }

    img {
        position: absolute;
        width: 40px;
        height: 40px;
        right: 32px;
        top: 32px;
    }
}

.pagination {
    margin-top: 24px;
    text-align: right;
}

.table-toolbar {
    overflow: hidden;
    margin: 20px 0 12px 0;

    h2 {
        float: left;
        font-size: 16px;
        margin: 0;
        height: 32px;
        line-height: 32px;
        color: #0a2a4c;
    }

    .export-btn {
        float: right;
    }
}

.data-table {
    background-color: #fff;
    padding: 20px;
    border-radius: 4px;
    box-shadow: 0px 2px 12px -4px rgba(0, 0, 0, 0.1);
}
</style>